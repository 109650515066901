import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from 'buffer';

// Utility to convert Base64 to Uint8Array
const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64); // Decode base64 string to binary
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

// AES decryption using Web Crypto API
export const decryptAES = async (encryptedData, iv, key) => {
  const importedKey = await window.crypto.subtle.importKey(
      'raw',
      Buffer.from(key), // Your 32-byte key as a Buffer
      { name: 'AES-CBC' },
      false,
      ['decrypt']
  );

  // Decrypt using AES-CBC
  const decrypted = await window.crypto.subtle.decrypt(
      {
          name: 'AES-CBC',
          iv: base64ToUint8Array(iv), // Convert Base64 IV to Uint8Array
      },
      importedKey,
      base64ToUint8Array(encryptedData) // Convert encrypted data from Base64
  );

  const decoder = new TextDecoder();
  return JSON.parse(decoder.decode(decrypted)); // Convert decrypted data back to a UTF-8 string
};

// import { refreshToken } from "../service/actions/authActions";
// import { useDispatch } from "react-redux";

const http = axios.create({
  // baseURL: "http://18.189.239.182:5000",
  // baseURL: "http://192.168.100.110:5000",
  // baseURL: 'http://192.168.18.50:4000',
  // baseURL: "http://localhost:5000/",
  // baseURL: "https://api.1lab.network/",
  baseURL:" https://production.earnscape.io/",
  //  baseURL: " https://staging.earnscape.io/"
  //  baseURL: "http://localhost:5000/"
});



// Initialize react-toastify
toast.configure();

http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.get["Content-Type"] = "application/json";
// http.interceptors.response.use(
//   async (response) => {
//     if (response.status >= 200 && response.status < 300) {
//       const message = response.data.message;
//       if (message) {
//         showToast(message);
//       }
//       return response.data;
//     }
//   },
//   async (error) => {
//     const { response, request } = error;

//     console.log("error", error);
//     if (response) {
//       if (response.status >= 400 && response.status <= 500) {
//         // alert(response.data?.message, 'error');

//           showToast(response.data?.message, "error");
//         return null;
//       }
//     } else if (request) {
//       showToast("Request failed. Please try again.", "error");
//       return null;
//     }
//     return Promise.reject(error);
//   }
// );


// Interceptor to refresh token if it has expired
http.interceptors.response.use(
  async (response) => {
    // logoutUser();

    // Check if response is successful
    if (response.status >= 200 && response.status < 300) {
      const key = '12345678901234567890123456789012'
      const decryptedResponse = await decryptAES(response.data.data, response.data.iv, key);
      const message = decryptedResponse.message;
      if (message) {
        showToast(message);
      }
      console.log(decryptedResponse)
      return decryptedResponse;
    }
  },
  async (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401 && !config.__isRetryRequest) {
        try {
          console.log('401 IN UNug');
          const newToken = refreshToken1(localStorage.getItem("refreshToken"));

          if (newToken && newToken.data && newToken.data.accessToken) {
            const newAccessToken = newToken.data.accessToken;
            config.headers['accessToken'] = newAccessToken;
            config.__isRetryRequest = true;
            return http(config);
          }
        } catch (refreshError) {
          if(refreshError.response && refreshError.response.status === 403){
            
          }
          showToast("Failed to refresh access token. Please log in again.", "error");
          logoutUser();


          return Promise.reject(error);
        }
      } else if (response.status >= 400 && response.status <500) {
        showToast(response.data?.message, "error");
        // logoutUser();
      }
    } else {
      showToast("Request failed. Please try again.", "error");
    }
    return Promise.reject(error);
  }
);


const refreshToken1 = async (refreshToken) => {
  console.log("refreshToken", refreshToken);
  try {
    const res = await http.post("/api/admin/adminRefreshToken", {
      refreshToken: refreshToken,
    });
    if (res && res.data && res.data.accessToken) {
      const newAccessToken = res.data.accessToken;
      
      localStorage.setItem("accessToken", newAccessToken);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};


const logoutUser= ()=>{
  localStorage.removeItem("accessToken");
  localStorage.removeItem("sessionToken");
    
}

http.generateConfig = (accessToken) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      "Content-Type": "application/json",
      "access-token": token ? token : accessToken,
    },
  };
};


const showToast = (message, type = "success") => {
  if (message) {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export default http;




